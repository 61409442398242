<template>
  <span
    v-tooltip="tooltip ?? $t('COMMON.COPY_TO_CLIPBOARD')"
    class="flex items-center justify-center rounded hover:bg-gray-100 cursor-pointer w-min h-min"
    data-cy="copy-record-button"
    @click="copy"
  >
    <icon-base
      class="text-disabled-medium"
      :icon="icon"
      :view-box="ICON_VIEWBOXES[icon]"
      height="20"
      width="20"
    />
  </span>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { useClipboardWithMessage } from "@/hooks/clipboard";
</script>
<script setup lang="ts">
const props = defineProps({
  value: {
    type: [String, Number],
    required: true
  },
  tooltip: {
    type: String,
    required: false
  },
  icon: {
    type: String as PropType<"copy" | "link">,
    default: "copy"
  }
});
const ICON_VIEWBOXES = {
  copy: "0 0 20 20",
  link: "0 0 24 24"
};
const copyToClipboard = useClipboardWithMessage();
const copy = async () => {
  await copyToClipboard(props.value);
};
</script>
